<template>
  <v-container fluid>
    <Menu
      style="position:fixed; height: 50px; z-index: 1;margin-top:-12px; background: #DBC7FF; left: 0; right: 0;"
      :employee="profile.role == 'Manager'"
    />
    <div class="pb-5 px-15" style="margin-top: 40px; background: #F4F9FF;">
      <v-card-title>
        <label class="item_title">Your Team Members</label>
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <label class="item_description">
          <span v-if="profile.role == 'Manager'"
            >Manage your team members information as the organization
            manager.</span
          >
          <span v-else>Your team members information</span>
        </label>
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="allPartners"
        hide-default-footer
        :loading="loading"
        class="px-3"
        :item-class="itemClass"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar class="elevation-6 my-3 ml-5">
            <v-img :src="item.image ? item.image : placeholderImage" />
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div v-html="getFiltered(item.first_name + ' ' + item.last_name)" />
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div v-html="getFiltered(formatPhoneNumber(item.phone))" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item._id == profile._id || profile.role == 'Manager'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onEditPartnerStocks(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-store</v-icon></v-btn
                >
              </template>
              Edit Stocks
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onEditEmployee(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onDeleteEmployee(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              Delete
            </v-tooltip>
          </div>
          <v-tooltip bottom v-if="item._id != profile._id">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="goToChat(item)" v-bind="attrs" v-on="on"
                ><v-icon>mdi-message-outline</v-icon></v-btn
              >
            </template>
            Chat
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this employer? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-organization-dialog
      :item="selected"
      :dialog="editDialog"
      :onCloseDialog="onCloseDialog"
      v-if="editDialog"
    />
    <employee-dialog
      :dialog="employeeDialog"
      :onCloseDialog="onCloseDialog"
      v-if="employeeDialog"
      :item="selected"
    />
    <partner-stocks-dialog
      :dialog="stocksDialog"
      :item="selected"
      :onCloseDialog="onCloseDialog"
      v-if="stocksDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import EditOrganizationDialog from "../../components/partner/EditOrganizationDialog.vue";
import EmployeeDialog from "../../components/partner/EmployeeDialog.vue";
import PartnerStocksDialog from "./PartnerStocksDialog.vue";
export default {
  components: {
    Menu,
    EditOrganizationDialog,
    EmployeeDialog,
    PartnerStocksDialog,
  },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Role", value: "role" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selected: null,
      placeholderImage: require("@/assets/default.jpg"),
      selectedFile: null,
      deleteDialog: false,
      editDialog: false,
      stocksDialog: false,
      search: "",
      partners: [],
      organizations: [],
      employeeDialog: false,
      // expanded: [],
      employeeData: {},
      loadingEmployee: [],
    };
  },
  computed: {
    ...mapGetters({
      allPartners: "partner/getAllPartners",
      allOrganizations: "partner/getAllOrganizations",
      keyword: "auth/getKeyword",
      profile: "auth/getProfile",
    }),
  },
  methods: {
    ...mapActions("partner", {
      fetchTeamMembers: "fetchTeamMembers",
      editEmployee: "editEmployee",
      deleteEmployee: "deleteEmployee",
      editOrganization: "editOrganization",
      deleteOrganization: "deleteOrganization",
      setBalance: "setBalance",
    }),
    onImageSelected(file) {
      this.selectedFile = file;
    },
    onChange() {
      this.errorMessage = null;
    },
    onSelectPartner(item) {
      console.log(item);
      // this.$router.push({ name: "partner-details", params: { id: item._id } });
    },
    onEditOrganization(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onCloseDialog() {
      this.editDialog = false;
      this.employeeDialog = false;
      this.stocksDialog = false;
    },
    onDeleteOrganization(item) {
      this.selected = item;
      this.deleteDialog = true;
    },

    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.loading = true;
      this.deleteEmployee(this.selected)
        .then(() => {
          this.loading = false;
          this.selected--;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error.response.data.message);
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] === "full_name") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }

      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        if (
          (`${item.first_name} ${item.last_name}` &&
            `${item.first_name} ${item.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            item.phone.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            this.formatPhoneNumber(item.phone)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address2 &&
            item.address2.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.type &&
            item.type.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.service &&
            item.service.toUpperCase().includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      return searchFilter;
    },
    onAddEmployee(item) {
      this.selected = { organization: item._id };
      this.employeeDialog = true;
    },
    onEditEmployee(item) {
      this.selected = { ...item };
      this.employeeDialog = true;
    },
    getEmployeeData(_id) {
      this.loadingEmployee[_id] = true;
      this.fetchEmployees({ _id })
        .then((res) => {
          this.loadingEmployee[_id] = false;
          this.employeeData[_id] = res;
          console.log(res);
          this.employeeData = { ...this.employeeData };
        })
        .catch((error) => {
          this.loadingEmployee[_id] = false;
          console.log(error.response.data.message);
        });
    },
    onDeleteEmployee(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    itemClass(item) {
      if (this.profile && item._id == this.profile._id) {
        return "selected";
      }
      return "";
    },
    goToChat(item) {
      this.$router.push({
        name: "chat-details",
        params: { id: "inbox" },
        query: {
          _id: item._id,
          user_type: "Partner",
        },
      });
    },
    onEditPartnerStocks(item) {
      this.selected = item;
      this.stocksDialog = true;
    },
  },
  created() {
    this.loading = true;
    this.fetchTeamMembers()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
  watch: {
    keyword(newValue) {
      this.search = newValue;
    },
    // expanded(newValue) {
    //   console.log(newValue);
    //   for (var i = 0; i < newValue.length; i++) {
    //     if (!this.employeeData[newValue[i]._id]) {
    //       this.getEmployeeData(newValue[i]._id);
    //     }
    //   }
    // },
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.partner_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-data-table >>> .selected {
  background-color: #fff4e0 !important;
}
</style>
