var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Menu',{staticStyle:{"position":"fixed","height":"50px","z-index":"1","margin-top":"-12px","background":"#DBC7FF","left":"0","right":"0"},attrs:{"employee":_vm.profile.role == 'Manager'}}),_c('div',{staticClass:"pb-5 px-15",staticStyle:{"margin-top":"40px","background":"#F4F9FF"}},[_c('v-card-title',[_c('label',{staticClass:"item_title"},[_vm._v("Your Team Members")])]),_c('v-card-subtitle',{staticClass:"mt-2"},[_c('label',{staticClass:"item_description"},[(_vm.profile.role == 'Manager')?_c('span',[_vm._v("Manage your team members information as the organization manager.")]):_c('span',[_vm._v("Your team members information")])])]),_c('v-data-table',{staticClass:"px-3",attrs:{"headers":_vm.headers,"items":_vm.allPartners,"hide-default-footer":"","loading":_vm.loading,"item-class":_vm.itemClass},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"elevation-6 my-3 ml-5"},[_c('v-img',{attrs:{"src":item.image ? item.image : _vm.placeholderImage}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.first_name + ' ' + item.last_name))}})]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(_vm.formatPhoneNumber(item.phone)))}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item._id == _vm.profile._id || _vm.profile.role == 'Manager')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditPartnerStocks(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-store")])],1)]}}],null,true)},[_vm._v(" Edit Stocks ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditEmployee(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteEmployee(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1):_vm._e(),(item._id != _vm.profile._id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToChat(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-outline")])],1)]}}],null,true)},[_vm._v(" Chat ")]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this employer? This action can not be undone.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.deleteAction}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),(_vm.editDialog)?_c('edit-organization-dialog',{attrs:{"item":_vm.selected,"dialog":_vm.editDialog,"onCloseDialog":_vm.onCloseDialog}}):_vm._e(),(_vm.employeeDialog)?_c('employee-dialog',{attrs:{"dialog":_vm.employeeDialog,"onCloseDialog":_vm.onCloseDialog,"item":_vm.selected}}):_vm._e(),(_vm.stocksDialog)?_c('partner-stocks-dialog',{attrs:{"dialog":_vm.stocksDialog,"item":_vm.selected,"onCloseDialog":_vm.onCloseDialog}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }